import { format, formatDistance, isAfter } from 'date-fns'

export function randomInRange(start: number, end: number) {
  return Math.floor(Math.random() * (end - start + 1) + start)
}

export function sequence(start: number, stop: number, step: number) {
  return Array.from({ length: (stop - start) / step + 1 }, (_: number, i: number) => start + i * step)
}

/**
 * return the first element in an array
 */
export function first<T>(arr: T[]): T | undefined {
  return arr.find(() => true)
}

export function empty(obj: unknown) {
  return obj === null || obj === undefined || obj === '' || (Array.isArray(obj) && obj.length === 0)
}

type primitive = undefined | null | boolean | string | number

export function arePrimitiveArraysEqual(a: primitive[], b: primitive[]) {
  return a.every((value: primitive, index: number) => {
    return value === b[index]
  })
}

export function uuid() {
  if (window?.crypto?.randomUUID) {
    return window.crypto.randomUUID()
  }

  return Array.from({ length: 36 })
    .map((_, i) => {
      if (i == 8 || i == 13 || i == 18 || i == 23) {
        return '-'
      } else if (i == 14) {
        return '4'
      } else if (i == 19) {
        return (Math.floor(Math.random() * 4) + 8).toString(16)
      } else {
        return Math.floor(Math.random() * 15).toString(16)
      }
    })
    .join('')
}

export type Authorizable = {
  can: {
    [key: string]: boolean
  }
}

export function can<T extends Authorizable>(ability: keyof T['can'] & string, authorizable: T) {
  return authorizable.can[ability]
}

export async function loadScript(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.addEventListener('load', () => resolve(script), false)
    script.addEventListener('error', () => reject(script), false)
    document.body.appendChild(script)
  })
}

export async function scrollErrorIntoView() {
  await wait(200)

  const el = document.querySelector('.v-form-error') as HTMLElement
  if (el) {
    el.closest('.field')?.scrollIntoView({
      behavior: 'smooth',
    })
  }
}

export function diffForHumans(date: string | Date) {
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export function formatDate(date: string | Date, formatString = 'd LLLL, HH:mm') {
  return format(new Date(date), formatString)
}

export function isPast(date: string | Date) {
  return isAfter(new Date(), new Date(date))
}

export function wait(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms))
}

export function takeAtLeast(p: Promise<any>, ms: number) {
  return new Promise((resolve, reject) => {
    Promise.all([p, wait(ms)]).then(([result]) => {
      resolve(result)
    }, reject)
  })
}

export const transformFlaggedEnum = (value: number, options: Record<string, string>) => {
  return Object.keys(options)
    .map((v) => parseInt(v, 10))
    .filter((k) => (value & k) === k) // eslint-disable-line no-bitwise
}

export function wrapArray<T = unknown>(value: unknown) {
  if (Array.isArray(value)) {
    return value as T[]
  }

  return [value] as T[]
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
