import { toast } from 'vue-sonner'

import { uuid } from '/@front:shared/utils'

export interface FlitsItem {
  id?: string
  level?: 'normal' | 'success' | 'info' | 'warning' | 'error' | 'default'
  message: string
  link?: string
}

export class Flits {
  public items: FlitsItem[] = []

  constructor() {
    this.items = []
  }

  public addMultiple(items: FlitsItem[] | boolean): void {
    if (items === false) {
      return
    }

    items = items as FlitsItem[]

    if (!Array.isArray(items)) {
      items = [items]
    }

    items.forEach((item, key) => {
      setTimeout(() => {
        this.addSingle(item)
      }, key * 300 + 50)
    })
  }

  public addSingle(item: FlitsItem | string, ttl = 8_000) {
    if (typeof item === 'string') {
      item = { level: 'error', message: item } as FlitsItem
    }

    item.id = uuid()

    toast.message(item.message, {
      duration: ttl,
      // If you add the 'normal' type, the message will render with a padding
      // but without an icon, omiting the type prevents that behavior.
      ...(item.level && { type: item.level }),
    })
  }

  public remove(item: FlitsItem): void {
    const index = this.items.findIndex((i) => {
      return i.id === item.id
    })

    this.items.splice(index, 1)
  }

  public success(message: string, link?: string): void {
    this.addSingle({
      level: 'success',
      message,
      link,
    })
  }

  public error(message: string, link?: string): void {
    this.addSingle({
      level: 'error',
      message,
      link,
    })
  }

  public info(message: string, link?: string): void {
    this.addSingle({
      message,
      link,
    })
  }
}

const flits = new Flits()

export { flits }
